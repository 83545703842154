<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker
                :show-time="{
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }"
                @change="time"
                format="YYYY-MM-DD HH:mm:ss">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="车牌号">
              <a-input v-model="queryParam.number" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/UndertakingForm'
import { Drawer, Tag } from 'ant-design-vue'
import { temporary_car_list } from '@/api/temporary'
import moment from 'moment'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    Drawer,
    Tag
  },
  data () {
    return {
      initTime: moment().format('00:00:00'),
      moment: moment,
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      DrawerVisible: false,
      current_corporation_auth_id: null,
      // 创建窗口控制
      visible: false,
      tags: [],
      selectedTags: [],
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '车牌号',
          dataIndex: 'number'
        },
        {
          title: '事由',
          dataIndex: 'reason'
        },
        {
          title: '手机号码',
          dataIndex: 'cellphone'
        },
        {
          title: '车辆行为',
          dataIndex: 'action',
          customRender: (text) => this.$Dictionaries.TemporaryCarAction[text]
        }
      ],
      previewurl: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return temporary_car_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    console.log(moment().format('YYYY-MM-DD 00:00:00'))
  },
  methods: {
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    checkFileType: function (suffix) {
      var type = ''
      switch (suffix) {
        case 'mp4':
          type = 'mp4'
          break
        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'pdf':
          type = 'office'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
        case 'gif':
          type = 'pic'
          break
      }
      return type
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    onCloseDrawer () {
      this.DrawerVisible = false
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
