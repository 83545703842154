var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":_vm.modalok,"cancel":_vm.modalcancel}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"图片信息"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['entrance_img', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['entrance_img', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"文件上传","extra":"文件类型要求：后缀为.jpg/.jpeg .png的图片或pdf文件"}},[_c('div',{staticClass:"clearfix"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'entrance_img_all',
                  {
                    valuePropName: 'entrance_img_all',
                    rules: [{required: true, message: '必填项，请填写信息'}]
                  },
                ]),expression:"[\n                  'entrance_img_all',\n                  {\n                    valuePropName: 'entrance_img_all',\n                    rules: [{required: true, message: '必填项，请填写信息'}]\n                  },\n                ]"}],attrs:{"name":"file","accept":"image/*, application/pdf","action":_vm.actionUrl,"data":_vm.new_multipart_params,"list-type":"picture-card","file-list":_vm.fileList,"before-upload":_vm.beforeUpload},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" Upload ")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }